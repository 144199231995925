import { http } from "@/http/axios.js";
// 区域树
export function getAreaTree(params) {
  return http({
    url: '/company/guideCity/tree',
    method: 'get',
    params
  })
}
// 网站栏目
export function getWebsiteCategoryListAPI(params) {
  return http({
    url: '/company/guideCategory/list',
    method: 'get',
    params
  })
}