import { http } from '@/http/axios.js'
// list
export function getListAPI(params) {
  return http({
    url: '/company/guideWebsite/list',
    method: 'get',
    params
  })
}
// detail
export function getDetailAPI(id) {
  return http({
    url: '/company/guideWebsite/detail',
    method: 'get',
    params: { id }
  })
}
// add
export function addAPI(data) {
  return http({
    url: '/company/guideWebsite/add',
    method: 'post',
    data
  })
}
// edit
export function editAPI(data) {
  return http({
    url: '/company/guideWebsite/edit',
    method: 'post',
    data
  })
}
// del
export function delAPI(id) {
  return http({
    url: '/company/guideWebsite/delete',
    method: 'post',
    data: { id }
  })
}
// intro
export function getSiteIntroAPI(params) {
  return http({
    url: '/company/guideWebsite/getIntro',
    method: 'get',
   params
  })
}
// save intro
export function saveSiteIntroAPI(data) {
  return http({
    url: '/company/guideWebsite/settingIntro',
    method: 'post',
   data
  })
}
