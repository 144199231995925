<template>
  <el-cascader
    :style="{ width: width }"
    size="small"
    :options="list"
    :show-all-levels="false"
    :props="cascProps"
    filterable
    placeholder="请选择"
    v-model="currentValue"
  ></el-cascader>
</template>

<script>
import { getAreaTree } from './api'
export default {
  name: 'SelectArea',

  props: {
    value: {
      type: [String, Number, Array],
      default: ''
    },
    width: {
      type: String,
      default: '400px'
    }
  },

  computed: {
    currentValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      list: [],
      cascProps: {
        label: 'name',
        value: 'id',
        checkStrictly: true,
        expandTrigger: 'hover',
        emitPath: true,
        multiple: false,
        leaf: 'leaf'
      }
    }
  },

  created() {
    this.getDeptTree()
  },

  methods: {
    async getDeptTree() {
      let res = await getAreaTree()
      this.list = this.setLeaf(res)
    },

    setLeaf(list) {
      return list.map(item => {
        if (item.children && item.children.length > 0) item.children = this.setLeaf(item.children)
        else {
          item.leaf = true
          delete item.children
        }
        return item
      })
    }
  }
}
</script>

<style></style>
