<template>
  <el-select
    :style="{ width: width }"
    size="small"
    v-model="selectValue"
    :loading="isLoading"
    filterable
    remote
    clearable
    :remote-method="getList"
    placeholder="请选择或输入关键字"
    ref="selectDom"
  >
    <el-option v-for="item in list" :label="item.name" :value="item.id" :key="item.id"></el-option>
  </el-select>
</template>

<script>
import { getWebsiteCategoryListAPI } from './api.js'
export default {
  name: 'SelectLawPeople',

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    width: {
      type: String,
      default: '400px'
    },
    search_area_id: {
      type: [Number, String],
      default: ''
    }
  },

  computed: {
    selectValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  created() {
    this.getList('')
  },
  mounted() {
    // this.$refs.selectDom.cachedOptions.push({
    //   currentLabel: this.value.name,
    //   currentValue: this.value.id,
    //   label: this.value.name,
    //   value: this.value.id
    // })
  },
  methods: {
    async getList(query) {
      let params = {
        page: 1,
        page_size: 20,
        name: query
      }
      let res = await getWebsiteCategoryListAPI(params)
      this.list = res.data
    }
  }
}
</script>

<style></style>
